import React, { useEffect, useState, useCallback } from "react";
import { Box, CircularProgress, Grid2 } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import AutoComplete, { Item } from "../components/AutoComplete";
import useFetchItemUpdates from "../hooks/useFetchItemUpdates";
import Chart from "../components/charts/Chart";
import { SHN_ANALYTICS_ICON_128_URL } from "../utils/static";

interface Series {
  data: any[];
  xKey: string;
  yKey: string;
  yName: string;
}

function mapData(data: any) {
  if (!data) return [];
  return data.reduce((acc: any, curr: any, i: number) => {
    const currVol = curr["volume"];
    const prevCumulativeVol =
      i === 0 ? -currVol : acc[i - 1]["cumulative_volume"];
    const volChange =
      currVol < (acc[i - 1]?.volume || 0)
        ? currVol
        : currVol - (acc[i - 1]?.volume || 0);

    acc.push({
      ...curr,
      datetime_created: curr["datetime_created"] * 1000,
      cumulative_volume: prevCumulativeVol + volChange,
    });
    return acc;
  }, []);
}

const ItemsViewPage = () => {
  const { loading, fetchData } = useFetchItemUpdates<any>();

  const [selectedDateEpoch, setSelectedDateEpoch] = useState<number>(() =>
    dayjs().startOf("day").subtract(1, "day").unix()
  );
  const [selectedItems, setSelectedItems] = useState<Item[]>([]);
  const [chartData, setChartData] = useState<any[]>([]);
  const [cache, setCache] = useState<{ [key: string]: any }>({}); // Cache for item data

  const [options, setOptions] = useState<{
    title: { text: string };
    theme: string;
    series: Series[];
    axes: { type: string; position: string; label: { format: string } }[];
  }>({
    title: { text: "Volume" },
    theme: "ag-default-dark",
    series: [],
    axes: [
      { type: "time", position: "bottom", label: { format: "%m-%d %H:%M" } },
      { type: "number", position: "left", label: { format: "#{0.0f}" } },
    ],
  });

  const fetchItemData = useCallback(
    async (item: Item, startTime: string) => {
      // Force refresh by bypassing cache on date change
      const data = await fetchData(item.item_id, startTime);
      const mappedData = mapData(data);
      setCache((prevCache) => ({
        ...prevCache,
        [item.item_id]: mappedData,
      }));
      return mappedData;
    },
    [fetchData]
  );

  useEffect(() => {
    const fetchAllData = async () => {
      const newChartData: any[] = [];
      const startTimeString = String(selectedDateEpoch);

      console.log("cache", cache);

      for (const item of selectedItems) {
        if (item.item_id in cache) {
          newChartData.push({
            itemName: item.item_name,
            data: cache[item.item_id],
          });
          continue;
        }
        const mappedData = await fetchItemData(item, startTimeString);
        newChartData.push({
          itemName: item.item_name,
          data: mappedData,
        });
      }

      console.log("newChartData", newChartData);

      setChartData(newChartData);
    };

    if (selectedItems.length > 0) {
      fetchAllData();
    } else {
      setChartData([]);
    }
  }, [selectedItems, selectedDateEpoch, fetchItemData, cache]); // Trigger on calendar change

  useEffect(() => {
    const newSeries: Series[] = chartData.map((item) => ({
      data: item.data,
      xKey: "datetime_created",
      yKey: "cumulative_volume",
      yName: item.itemName,
    }));

    setOptions((old) => ({
      ...old,
      series: newSeries,
    }));
  }, [chartData]);

  const handleDateChange = (newDate: Dayjs | null) => {
    if (newDate) {
      setCache({});
      setSelectedDateEpoch(newDate.startOf("day").unix());
    }
  };

  const shouldDisableDate = (date: Dayjs) => {
    const aWeekAgo = dayjs().subtract(7, "day");
    const today = dayjs().endOf("day");
    return date.isBefore(aWeekAgo, "day") || date.isAfter(today, "day");
  };

  return (
    <>
      <Grid2 container spacing={2} alignItems="center" marginBottom={2}>
        <Grid2 size={{ xs: 12, sm: 2 }} display="flex" justifyContent="center">
          <Grid2 alignItems="center" display="flex" justifyContent="center">
            <Box
              component="img"
              src={SHN_ANALYTICS_ICON_128_URL}
              height={32}
              alt="logo"
              sx={{
                marginRight: "10px", // Spacing between the image and text
                borderRadius: "8px", // Adjust radius as needed
              }}
            />
            <h2>shn.gg</h2>
          </Grid2>
        </Grid2>
        <Grid2
          container
          size={10}
          spacing={2}
          alignItems="center"
          display="flex"
          justifyContent="end"
        >
          <Grid2 size={"grow"}>
            <AutoComplete
              onAutoCompleteChange={setSelectedItems}
              value={selectedItems}
            />
          </Grid2>
          <Grid2 size={{ xs: 12, sm: "auto" }} display="flex" alignItems="center">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                value={dayjs.unix(selectedDateEpoch)}
                onChange={handleDateChange}
                shouldDisableDate={shouldDisableDate}
              />
            </LocalizationProvider>
          </Grid2>
        </Grid2>
      </Grid2>
      {selectedItems.length ? (
        loading ? (
          <Grid2
            container
            spacing={2}
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={{ minHeight: "10vh" }}
          >
            <CircularProgress />
          </Grid2>
        ) : (
          <Chart options={options} />
        )
      ) : (
        <Grid2
          container
          spacing={2}
          direction="column"
          alignItems="center"
          justifyContent="center"
          sx={{ minHeight: "10vh" }}
        >
          <h3>Please search for items to display data.</h3>
        </Grid2>
      )}
    </>
  );
};

export default ItemsViewPage;
